import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppState } from '../states/app.state';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(private state:AppState,private router:Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const {user}=this.state.auth$.value;
        if(!user || !user._id){
            this.router.navigate(['/auth/login'])
            return false;
        }
        return true;
    }


}