export enum AuthTypes {
    'AFFILIATE'='AF',
    'ADMIN'='ADMIN'
}


export enum SettingsEnum {
    'STAGES'='STAGES',
    'ACCOUNTTYPES'='ACCOUNT-TYPES',
    'USERS'='USERS',
    'AFPASSWORD'='AFFS'
}


export type SettingsType='STAGES'|'ACCOUNT-TYPES'|'USERS'|'AFFS'|'';