import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountsInterface } from '../models/accounts.model';
import { AffiliatesInterface } from '../models/affiliates.model';
import { AuthInterface } from '../models/auth.model';
import { LeadInterface } from '../models/leads.model';
import { StagesInterface } from '../models/stages.model';
import { IUser } from '../models/users.model';


const auth={token:'',type:'',user:null};

@Injectable({
    providedIn: 'root'
})
export class AppState {

    auth$:BehaviorSubject<AuthInterface>=new BehaviorSubject(auth);
    affiliates$:BehaviorSubject<AffiliatesInterface[]>=new BehaviorSubject([]);
    leads$:BehaviorSubject<LeadInterface[]>=new BehaviorSubject([]);
    stages$:BehaviorSubject<StagesInterface[]>=new BehaviorSubject([]);
    accounts$:BehaviorSubject<AccountsInterface[]>=new BehaviorSubject([]);
    users$:BehaviorSubject<IUser[]>=new BehaviorSubject([]);
    dashboard$:BehaviorSubject<any>=new BehaviorSubject(null);
    selected$:BehaviorSubject<any>=new BehaviorSubject(null);
    filter$:BehaviorSubject<any>=new BehaviorSubject(null);
    searchTerm$:BehaviorSubject<string>=new BehaviorSubject('');

    constructor(){};

    logout():void{
        this.auth$.next(auth)
    }

    setFilter(filter:any){
        this.filter$.next(filter);
    }

    setUser(user:AuthInterface):void{
        this.auth$.next(
            user
        )
    }

    setSelected(data:any):void{
        this.selected$.next(
            data
        )
    }

    setLeads(leads:LeadInterface[]):void{
        this.leads$.next(
            leads
        )
    }

    setAffiliates(aff:AffiliatesInterface[]):void{
        this.affiliates$.next(
            aff
        )
    }

    onSearch(term:string){
        this.searchTerm$.next(
            term
        )
    }


    
}